@keyframes sky {
  0%      { transform: translate3d(0,0,0); background-color: mix($color-primary, white, 50%);  }
  50%     { transform: translate3d(0,0,0); background-color: mix($color-primary, white, 15%);  }
  75%     { transform: translate3d(0,0,0); background-color: mix($color-secondary, white, 35%);  }
  100%    { transform: translate3d(0,0,0); background-color: mix($color-primary, white, 50%);  }
}

@keyframes sun {
  0%      { background-color: transparent; transform: translate3d(0,0,0) rotate(0deg) }
  15%     { background-color: rgba(white,.05) }
  40%     { background-color: rgba(#fcfbcd,.5) }
  100%    { background-color: transparent; transform: translate3d(0,0,0) rotate(180deg) }
}

@keyframes star {
  0%      { opacity: 1 }
  30%     { opacity: 0 }
  85%     { opacity: 0 }
  90%     { opacity: 1 }
}

@keyframes logo {
  0%      { transform: translate3d(-50%,0,0) }
  50%     { transform: translate3d(-50.5%,-.5%) }
  100%    { transform: translate3d(-50%,0,0) }
}

@keyframes face {
  0%      { transform: translate3d(0,0,0) }
  50%     { transform: translate3d(.2rem,.4rem,0) rotate(-.5deg);  }
  100%    { transform: translate3d(0,0,0) }
}

@keyframes leg {
  0%      { transform: translate3d(0,0,0) }
  50%     { transform: translate3d(.4rem,-.2rem,0) rotate(2deg);  }
  100%    { transform: translate3d(0,0,0) }
}

@keyframes eye {
  0%      { transform: translate3d(0,0,0) }
  33%     { transform: translate3d(.3%,.1%,0) }
  61%     { opacity: 1 }
  63%     { opacity: 0 }
  66%     { opacity: 1; transform: translate3d(-.1%,.3%,0) }
  76%     { transform: translate3d(-.1%,.1%,0) scale(1.2) }
  100%    { transform: translate3d(0,0,0) }
}

@keyframes walrus1 {
  0%      { transform: translate3d(0,100%,0) }
  7%      { transform: translate3d(0,100%,0) }
  15%     { transform: translate3d(0,40%,0) }
  17%     { transform: translate3d(0,100%,0) }
  30%     { transform: translate3d(0,0,0) }
  60%     { transform: translate3d(0,0,0) }
  70%     { transform: translate3d(0,100%,0) }
}

@keyframes walrus2 {
  25%     { transform: translate3d(0,100%,0) }
  35%     { transform: translate3d(0,0,0) }
  50%     { transform: translate3d(0,0,0) }
  60%     { transform: translate3d(0,100%,0) }
}
