#header {
  animation-duration: 1s;
}

#sky {
  background-color: mix($color-primary, white, 25%);
  animation: sky linear 18s infinite .25s;
  box-shadow: inset 0 -.2rem 0 white, inset 0 -2rem 2rem rgba($color-primary, .1);
}

#sun {
  background-color: transparent;
  width: 10%;
  left: 5%;
  transform-origin: 450% 100%;
  animation: sun linear 18s infinite;
}

#light {
  background: radial-gradient(ellipse at center, rgba(white,0.3) 0%,rgba(white,0.3) 27%,rgba(white,0) 63%,rgba(white,0) 100%);
  width: 40%;
  bottom: -40%;
}

#star-1,
#star-2,
#star-3,
#star-4,
#star-5,
#star-6 {
  width: .4rem;
  height: .4rem;
  animation: star linear 18s infinite;
}

#star-1 {
  left: 30%;
  top: 30%;
}

#star-2 {
  left: 70%;
  top: 20%;
  animation-delay: .5s;
}

#star-3 {
  left: 45%;
  top: 10%;
  animation-delay: 1s;
}

#star-4 {
  left: 10%;
  top: 40%;
  animation-delay: .75s;
}

#star-5 {
  left: 90%;
  top: 45%;
  animation-delay: .25;
}

#star-5 {
  left: 80%;
  top: 60%;
  animation-delay: 1.25;
}


#logo {
  width: 36%;
  min-width: 18rem;
  max-width: 30rem;
  bottom: 18%;
  animation: logo ease 5s infinite;
}

#leg-1,
#eye-1,
#nose {
  animation: face ease 5s infinite;
}

#leg-1 {
    animation-delay: 2s;
}

#eye-1 {
  animation-delay: .5s;
}

#leg-2 {
  animation: leg ease 5s infinite .1s;
}

#eye-2 {
  animation: eye ease 10s infinite;
  transform-origin: 75% 10%;
}


#walrus-1,
#walrus-2,
#walrus-3 {
  transform: translate3d(0,100%,0);
  animation: walrus2 ease-in 18s infinite;
  color: rgba(mix($color-secondary,$color-dark,50%),.15);
}

#walrus-1 {
  width: 12%;
  right: 10%;
  animation-name: walrus1;
  color: rgba(mix($color-primary,$color-dark,50%),.15);
}

#walrus-2 {
  width: 10%;
  left: 10%;
  animation-delay: 4.5s;
}

#walrus-3 {
  width: 10%;
  left: 22%;
  animation-delay: 5s;
}

#walrus-welcome {
  color: mix($color-dark,white,90%);
  max-height: 120%;
}

#header-art {
  max-height: 80vh;
}
